import type { CoordinatesDto } from "@/api/dto/coordinates.dto";
import type { OrderStopType } from "@/data/order";

export interface CheckCallResponse {
  checkCalls: CheckCallDTO[];
}

export interface CreateCheckCallRequest {
  checkCallTime: string;
  locationAddress: string;
  locationCoordinates: CoordinatesDto;
}

export interface CheckCallDTO {
  id: number;
  type: CheckCallType;
  facilityId?: number;
  routeStopId?: number;
  routeStopType?: OrderStopType;
  routeStopReload?: boolean;
  timezone: string;
  checkCallTime: string;
  createdAt: string;
  createdDispatcher?: CheckCallCreatorDTO;
  createdUser?: CheckCallCreatorDTO;
  locationAddress: string;
  locationCoordinates: CoordinatesDto;
  nextStopDistance?: number;
  lastStopDistance?: number;
  lastCallDistance?: number;
  avgSpeed?: number;
  distanceReducing?: number;
  currentDeviation?: number;
  currentDeviationStatus?: DeviationStatus;
  accumulatedDeviation?: number;
  accumulatedDeviationStatus?: DeviationStatus;
  eta?: string;
}

export interface CheckCallInfoDTO {
  truck: CheckCallTruckDTO;
  routePolyline: string | null;
  checkCallsPolyline: string | null;
  checkCalls: CheckCallDTO[];
  routeStops: CheckCallRouteStopDTO[];
  nextRouteStop: CheckCallNextRouteStopDTO | null;
}

export interface CheckCallCreatorDTO {
  id: number;
  name: string;
}

export interface CheckCallTruckDTO {
  id: number;
  number: string;
  currentAddress: string;
  currentLocation: CoordinatesDto | null;
  currentLocationTime: string | null;
  currentLocationTimezone: string | null;
}

export interface CheckCallRouteStopDTO {
  id: number;
  type: OrderStopType;
  position: number;
  facilityLocation: CoordinatesDto;
  isReload: boolean;
}

export interface CheckCallNextRouteStopDTO {
  id: number;
  type: OrderStopType;
  address: string;
  isReload: boolean;
  name: string,
  eta: string;
}

export enum CheckCallType {
  checkCall = "check-call",
  dispatchConfirmed = "dispatch-confirmed",
  checkedIn = "checked-in",
  load = "load",
  loadVerify = "load-verify",
  unload = "unload",
  unloadVerify = "unload-verify",
  goodToGo = "good-to-go",
}

export enum DeviationStatus {
  onTrack = "on-track",
  deviated = "deviated",
  wrongWay = "wrong-way",
}
