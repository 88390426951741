import { BaseApi } from "@/api/base";
import type { Response } from "@/api/client";
import type {
  CheckCallDTO,
  CheckCallInfoDTO,
  CheckCallResponse,
  CreateCheckCallRequest,
} from "@/api/trips/dto/check-call";

export class EventsApi extends BaseApi {
  getCheckCalls(routeId: number): Promise<Response<CheckCallResponse>> {
    return this.client.get("/routes/" + routeId + "/check-calls");
  }

  getCheckCallInfo(routeId: number): Promise<Response<CheckCallInfoDTO>> {
    return this.client.get("/routes/" + routeId + "/check-call-info");
  }

  createCheckCall(routeId: number, data: CreateCheckCallRequest): Promise<Response<CheckCallDTO>> {
    return this.client.post("/routes/" + routeId + "/check-calls", { ...data, commit: true });
  }

  previewCheckCall(routeId: number, data: CreateCheckCallRequest): Promise<Response<CheckCallDTO>> {
    return this.client.post("/routes/" + routeId + "/check-calls", { ...data, commit: false });
  }
}
